var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "500px", height: "400px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.CSBK100.006"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _vm._m(1),
            _c("tbody", [
              _c("tr", [
                _c("td", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.parentInfo.blNo)),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.157")))]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.$ekmtcCommon.changeDatePattern(_vm.etaObrdDt))
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "border_left" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.156"))),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.$ekmtcCommon.changeDatePattern(_vm.etdObrdDt))
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.dateList.length > 0
            ? _c("table", { staticClass: "tbl_col mt10" }, [
                _vm._m(2),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.075")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.006")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.dateList, function (vo, idx) {
                    return _c("tr", { key: "date_" + idx }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedIdx,
                              expression: "selectedIdx",
                            },
                          ],
                          attrs: { type: "radio", id: "dateIdx_" + idx },
                          domProps: {
                            value: idx,
                            checked: _vm._q(_vm.selectedIdx, idx),
                          },
                          on: {
                            change: function ($event) {
                              _vm.selectedIdx = idx
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dateIdx_" + idx } }, [
                          _c("span", { staticClass: "offscreen" }, [
                            _vm._v("No"),
                          ]),
                        ]),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.$ekmtcCommon.changeDatePattern(vo))),
                      ]),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]),
        _vm.errMsg
          ? _c("div", { staticClass: "mt10 ml10 err_msg" }, [
              _c("p", [_vm._v(_vm._s(_vm.errMsg))]),
              _c("p", [_vm._v(_vm._s(_vm.errMsg2))]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "mt10 text_center" }, [
          !_vm.errMsg
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  on: {
                    click: function ($event) {
                      return _vm.selectDate()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.137")))]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.179")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("B/L No.")]),
        _c("th", [_vm._v("On Board")]),
        _c("th", [_vm._v("Date")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "80%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }