<template>
  <div class="popup_wrap" style="width:500px; height:400px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.CSBK100.006') }}</h1> <!-- On Board Date -->
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="35%">
            <col width="30%">
            <col width="35%">
          </colgroup>
          <thead>
            <tr>
              <th>B/L No.</th>
              <th>On Board</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="2">{{ parentInfo.blNo }}</td>
              <td>{{ $t('msg.CSBK100.157') }}</td>
              <td>{{ $ekmtcCommon.changeDatePattern(etaObrdDt) }}</td>
            </tr>
            <tr>
              <td class="border_left">{{ $t('msg.CSBK100.156') }}</td>
              <td>{{ $ekmtcCommon.changeDatePattern(etdObrdDt) }}</td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt10" v-if="dateList.length > 0">
          <colgroup>
            <col width="20%">
            <col width="80%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBL200.075') }}</th> <!-- 선택 -->
              <th>{{ $t('msg.CSBK100.006') }}</th> <!-- On Board Date -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in dateList" :key="'date_' + idx">
              <td>
                <input
                  v-model="selectedIdx"
                  type="radio"
                  :id="'dateIdx_' + idx"
                  :value="idx"
                >
                <label :for="'dateIdx_' + idx"><span class="offscreen">No</span></label>
              </td>
              <td>{{ $ekmtcCommon.changeDatePattern(vo) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="errMsg" class="mt10 ml10 err_msg">
        <p>{{ errMsg }}</p>
        <p>{{ errMsg2 }}</p>
      </div>

      <div class="mt10 text_center">
        <a v-if="!errMsg" class="button blue lg" @click="selectDate()">{{ $t('msg.CSBK100.137') }}</a>
        <a class="button gray lg" @click="$emit('close')">{{ $t('msg.CSBK100.179') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import trans from '@/api/rest/trans/trans'

export default {
  name: 'BLOnBoardDatePop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          bkgNo: '',
          blNo: '',
          flag: ''
        }
      }
    }
  },
  data () {
    return {
      dateList: [],
      etaObrdDt: '',
      etdObrdDt: '',
      selectedIdx: '',
      errMsg: ''
    }
  },
  created () {
    this.getBoardDateInfo()
  },
  methods: {
    getBoardDateInfo () {
      const params = {
        bkgNo: this.parentInfo.bkgNo,
        blNo: this.parentInfo.blNo,
        flag: this.parentInfo.flag
      }

      trans.findPolEtaEtd(params).then(res => {
        const result = res.data

        if (result !== undefined) {
          this.etaObrdDt = result.etaObrdDt
          this.etdObrdDt = result.etdObrdDt

          this.setDateList()
        }
      })
    },
    setDateList () {
      if (this.$ekmtcCommon.isNotEmpty(this.etaObrdDt) && this.$ekmtcCommon.isNotEmpty(this.etdObrdDt)) {
        const etaObrdDate = this.$ekmtcCommon.getStrToDate(this.etaObrdDt)
        const etdObrdDate = this.$ekmtcCommon.getStrToDate(this.etdObrdDt)
        if (this.etaObrdDt === '00000000') {
          const dateList = []
          dateList.push(this.etaObrdDt)

          this.$set(this, 'dateList', dateList)
        } else if (this.etaObrdDt <= this.etdObrdDt) {
          let diff = Math.abs(etdObrdDate.getTime() - etaObrdDate.getTime())
          diff = Math.floor(diff / (1000 * 3600 * 24))

          const dateList = []
          const calDate = etaObrdDate.getDate()
          for (let i = 0; i <= diff; i++) {
            const bfDate = new Date(etaObrdDate)
            const obrdDate = new Date(bfDate.setDate(calDate + i))
            dateList.push(this.$ekmtcCommon.getDateToStrDelim(obrdDate))
          }

          this.$set(this, 'dateList', dateList)
        } else {
          // this.errMsg = this.$t('msg.CSBL200.275') // 입항일이 출항일보다 큽니다.\n담당자에게 문의 바랍니다.
          // this.errMsg2 = this.$t('js.common.011') // 담당자에게 문의 바랍니다.

          const dateList = []
          dateList.push(this.$ekmtcCommon.getDateToStrDelim(etaObrdDate))
          dateList.push(this.$ekmtcCommon.getDateToStrDelim(etdObrdDate))

          this.$set(this, 'dateList', dateList)
        }
      }
    },
    selectDate () {
      if (this.$ekmtcCommon.isEmpty(this.selectedIdx)) {
        this.$ekmtcCommon.alertDefault('날짜를 선택해 주시기 바랍니다.')
        return
      }

      if (this.dateList.length > 0) {
        const date = this.dateList[Number(this.selectedIdx)]

        this.$emit('selectFunc', date)
      }
    }
  }
}
</script>

<style scoped>
  .err_msg {color: red; font-weight: 500;}
</style>
